import React from "react"

import Layout from '../components/layout'
import GamesTabbed from '../components/games_tabbed'
import Head from '../components/head'
import { useStaticQuery } from "gatsby"

const Games = () => {

    const data = useStaticQuery(graphql`
        query {
            allProjectedSpreadsCsv {
            nodes {
                game_id
                season
                week
                home_team
                away_team
                home_team_name
                away_team_name
                home_538_qb
                away_538_qb
                home_team_logo_espn
                away_team_logo_espn
                home_line_close
                home_closing_line_rounded_nfelo
                home_ev
                away_ev
                home_probability_nfelo
                last_week
                last_season
                gameday
            }
            }
        }
    `)

    const markupData = data.allProjectedSpreadsCsv.nodes.map(node => {
        return (
            {
                "@context": "http://schema.org",
                "@type": "SportsEvent",
                "name" : `${node.away_team_name} @ ${node.home_team_name}, Week ${node.week}, ${node.season}`,
                "description" : `Projected spread and win probability for ${node.away_team_name} @ ${node.home_team_name} -- ${node.gameday}`,
                "image" : node.home_team_logo_espn,
                "sport" : "Football",
                "startDate" : node.gameday,
                "endDate" : node.gameday,
                "location" : `${node.home_team_name} Stadium`,
                "homeTeam" : {
                    "@context": "http://schema.org",
                    "name": node.home_team_name,
                    "@type": "SportsTeam",
                    "sameAs": `https://www.nfeloapp.com/teams/${node.home_team.toLowerCase()}/${node.season}/`,
                    "logo" : node.home_team_logo_espn,
                    "sport" : "Football",
                    "athlete" : {
                        "@context": "http://schema.org",
                        "@type": "Person",
                        "givenName" : node.home_538_qb
                    }
                },
                "awayTeam" : {
                    "@context": "http://schema.org",
                    "name": node.away_team_name,
                    "@type": "SportsTeam",
                    "sameAs": `https://www.nfeloapp.com/teams/${node.away_team.toLowerCase()}/${node.season}/`,
                    "logo" : node.away_team_logo_espn,
                    "sport" : "Football",
                    "athlete" : {
                        "@context": "http://schema.org",
                        "@type": "Person",
                        "givenName" : node.away_538_qb
                    }
                }
            }
        )
    })

    return (
        <Layout>
            <Head
                title="Games"
                pageDescription="Data driven NFL predictions and ATS picks"
                schemaMarkup={markupData}
            />
            <GamesTabbed
                activeIndex={0}
            />
        </Layout>
    )
}

export default Games

